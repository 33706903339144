var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-features" }, [
    _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "col-12 col-md-4 mb-4 mb-md-0" }, [
        _c(
          "div",
          { staticClass: "w-75 mx-auto" },
          [
            _c("feather-icon", { attrs: { icon: "AwardIcon", size: "35" } }),
            _c("h4", { staticClass: "mt-2 mb-1" }, [_vm._v(" 100% Original ")]),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                " Chocolate bar candy canes ice cream toffee. Croissant pie cookie halvah. "
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-12 col-md-4 mb-4 mb-md-0" }, [
        _c(
          "div",
          { staticClass: "w-75 mx-auto" },
          [
            _c("feather-icon", { attrs: { icon: "ClockIcon", size: "35" } }),
            _c("h4", { staticClass: "mt-2 mb-1" }, [
              _vm._v(" 10 Day Replacement ")
            ]),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                " Marshmallow biscuit donut dragée fruitcake. Jujubes wafer cupcake. "
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-12 col-md-4 mb-4 mb-md-0" }, [
        _c(
          "div",
          { staticClass: "w-75 mx-auto" },
          [
            _c("feather-icon", { attrs: { icon: "ShieldIcon", size: "35" } }),
            _c("h4", { staticClass: "mt-2 mb-1" }, [
              _vm._v(" 1 Year Warranty ")
            ]),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                " Cotton candy gingerbread cake I love sugar plum I love sweet croissant. "
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }